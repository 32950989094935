const manageBackToTop = {
    setup : function() {
        this.$component = $('#btn_backToTop'); 
        this.$htmlBody = $('html,body');
        this.animationDuration = 800;
        this.animationDurationFade = 300;

        if(this.$component.length === 0) return;
        this.bindEvents();
    },

    bindEvents:  function()  {
        this.clickScrollTop();
        this.checkInitScrollTop();

        $(window).scroll(
          function () {
            manageBackToTop.checkInitScrollTop();
         });
        
    },

    clickScrollTop: function() {
        this.$component.on('click',()=> {
            this.$htmlBody.animate({ scrollTop: 0 }, this.animationDuration);
        });
    },

    checkInitScrollTop: function() {
        $(window).scrollTop() > $(window).height() ? this.showBacktoTop() : this.hideBackToTop();
    },

    showBacktoTop: function() {
        this.$component.fadeIn(this.animationDurationFade);
    },
    
    hideBackToTop: function() {
        this.$component.fadeOut(this.animationDurationFade);
    },


    init: function () {
      try {
        this.setup();
      }
      catch (e) {
        console.error(e);
      } 
    }
}; 
 
  

$(document).ready(function() {
    manageBackToTop.init();
});


